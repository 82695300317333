<template>
    <div>
        <Header />
        <Usuarios />
        <Footer />
    </div>
</template>

<script>
import Usuarios from '../components/Usuarios/Usuarios.vue';

export default {
    components: {
        Usuarios,
    },
};
</script>