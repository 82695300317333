<template>
    <v-dialog v-model="show" width="unset">
        <v-card>
            <v-card-title class="d-flex justify-center">Nuevo usuario</v-card-title>
            <v-card-text>
                <v-form @submit.prevent="createUser" method="POST">
                    <v-row>
                        <v-col>
                            <v-text-field v-model="username"
                                label="Usuario" type="text"
                                @input="$v.username.$touch()"
                                @blur="$v.username.$touch()"
                            >
                            </v-text-field>
                            <span class="red--text" v-if="!$v.username.required && submited">El usuario es requerido</span>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="password"
                                label="Contraseña" type="password"
                                @input="$v.password.$touch()"
                                @blur="$v.password.$touch()"
                            >
                            </v-text-field>
                            <span class="red--text" v-if="!$v.password.required && submited">El contraseña es requerida</span>
                        </v-col>
                    </v-row>
                    <div class="d-flex justify-end">
                        <v-btn @click="closeDialog">Cancelar</v-btn>
                        <v-btn type="submit" color="primary" class="ml-2">Crear</v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
    data() {
        return {
            show: false,
            username: '',
            password: '',
            submited: false,
        };
    },
    validations: {
        username: {
            required
        },
        password: {
            required
        }
    },
    methods: {
        async createUser() {
            this.submited = true;
            this.$v.$touch();
            if (this.$v.$invalid)
                return;
            const response = await this.$store.dispatch("insertUser", {
                username: this.username,
                password: this.password,
            });
            this.show = false;
            this.$emit("create-user");
            this.username = '';
            this.password = '';
        },
        closeDialog() {
            this.show = false;
            this.username = '';
            this.password = '';
            this.submited = false;
        }
    },
};
</script>