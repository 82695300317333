<template>
    <div>
        <Notification type="error" :message="message" ref="error_noti" />
        <Notification type="success" :message="message" ref="ok_noti" />
        <Dialog message="Deseas eliminar a este usuario" @result_ok="confirmDelete" ref="deleteItem" />
        <CrearUsuario @create-user="createUser" ref="createUserDialog" />
        <EditarUsuario @update-user="updateUser" ref="updateUserDialog" />
        <v-container>
            <v-data-table
                :headers="headers"
                :items="items"
                :loading="loading"
                class="elevation-1 mb-8"
            >
                <template #top>
                    <v-toolbar flat color="secondary">
                        <v-btn @click="openCreateDialog">
                            <v-icon>mdi-plus-circle</v-icon>
                            Nuevo Usuario
                        </v-btn>
                    </v-toolbar>
                </template>
                <template #[`item.action`]="{ item }">
                    <v-icon class="mr-2" @click="openEditDialog(item)">mdi-pencil</v-icon>
                    <v-icon @click="deleteUser(item)">mdi-delete</v-icon>
                </template>
            </v-data-table>
        </v-container>
    </div>
</template>

<script>
import CrearUsuario from './Usuarios/CrearUsuario.vue';
import EditarUsuario from './Usuarios/EditarUsuario.vue';

export default {
    components: {
        CrearUsuario,
        EditarUsuario,
    },
    data() {
        return {
            headers: [
                { text: 'Usuario', value: 'username', align: 'center' },
                { text: 'Acciones', value: 'action', align: 'center' },
            ],
            items: [],
            loading: false,
            itemToDelete: null,
            message: '',
        }
    },
    methods: {
        async initialize() {
            try {
                this.loading = true;
                const data = await this.$store.dispatch("getUsuarios");
                this.items = data;
                this.loading = false;
            } catch (error) {
                console.error(error);
            }
        },
        async confirmDelete() {
            const { status } = await this.$store.dispatch("deleteUser", {
                usuario_id: this.itemToDelete
            });
            if (status !== 200) {
                this.message = "Error eliminando el usuario";
                this.$refs.error_noti.Show();
                return;
            }

            await this.initialize();
            this.message = "Usuario eliminado con exito";
            this.$refs.ok_noti.Show();
        },
        deleteUser({ usuario_id }) {
            this.itemToDelete = usuario_id;
            this.$refs.deleteItem.Show();
        },
        openCreateDialog() {
            this.$refs.createUserDialog.show = true;
        },
        openEditDialog({ usuario_id, username }) {
            this.$refs.updateUserDialog.show = true;
            this.$refs.updateUserDialog.username = username;
            this.$refs.updateUserDialog.usuario_id = usuario_id;
        },
        async updateUser() {
            await this.initialize();
            this.message = "Usuario actualizado con exito";
            this.$refs.ok_noti.Show();
        },
        async createUser() {
            await this.initialize();
            this.message = "Usuario creado con exito";
            this.$refs.ok_noti.Show();
        },
    },
    created: function () {
        this.initialize();
    }
}
</script>